import api from '@/apis/api'

/**
 * 扫码生产相关api
 */
export class scanInfoApi extends api {
    /**
     * 获取订单详情
     * @param id
     * @returns {AxiosPromise}
     */
    getScanInfo(id) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/get/` + id,
            method: 'get'
        })
    }

    /**
     * 订单状态变更
     * @param data
     * @returns {AxiosPromise}
     */
    changeScanState(data) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/change`,
            method: 'post',
            data: data
        })
    }

    /**
     * 扫码录入
     * @param data
     * @returns {AxiosPromise}
     */
    saveScan(data) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/input`,
            method: 'post',
            data: data
        })
    }

    /**
     * 导出扫码记录
     * @param data
     * @returns {AxiosPromise}
     */
    exportScanRecord(data) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/export`,
            method: 'post',
            responseType: 'blob',
            data: data
        })
    }
}

export default new scanInfoApi('scanInfo')
